<template>
  <Loading v-if="isLoading" />
  <div v-else class="payment-method">
    <div class="p-2 mb-1">
      <h1 class="m-0 page-title">
        {{ $t("payment_methods.urway") }}
      </h1>
    </div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12" class="mb-1">
          <div class="images-input">
            <button
              v-if="catEdit"
              class="add-image-icon"
              @click="$refs.PaymentMethodIcoInput.$el.click()"
            >
              <feather-icon icon="PlusIcon" />
            </button>
            <button
              v-for="item of iconsUrl"
              :key="item.id"
              style="all: unset"
              class="icon-image"
            >
              <b-avatar rounded size="3rem" :src="item.path" />
              <span v-if="catEdit" class="hvr-icon-content">
                <button @click="deleteMe(item)">
                  <feather-icon
                    width="20"
                    height="20"
                    icon="Trash2Icon"
                    stroke="red"
                  />
                </button>
              </span>
            </button>
          </div>
          <b-form-file
            @change="handelIconsChange"
            ref="PaymentMethodIcoInput"
            plain
            multiple
            hidden="hidden"
          />
          <RecommendationUploadImage
            :dimensions="{ width: 512, height: 512 }"
          />
        </b-col>

        <b-col md="12" class="d-flex">
          <b-form-checkbox
            :disabled="!catEdit"
            v-model="form_data.is_active"
            :value="1"
            :unchecked-value="0"
            class="enable-class mr-1"
          >
            {{ $t("payment_methods.enable") }}
          </b-form-checkbox>
          <b-form-checkbox
            :disabled="!catEdit"
            v-model="settings.urway_mode"
            switch
            :value="1"
            :unchecked-value="0"
            class="enable-class"
          >
            {{
              !!settings.urway_mode
                ? $t("payment_methods.liveMode")
                : $t("payment_methods.testMode")
            }}
          </b-form-checkbox>
        </b-col>

        <b-col md="12">
          <b-form-group :label="$t('payment_methods.label')" label-for="Name">
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="form_data.name"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group> </b-col
        ><b-col md="4">
          <b-form-group
            :label="$t('payment_methods.urway_terminal_id')"
            label-for="Name"
          >
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="settings.urway_terminal_id"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('payment_methods.urway_password')"
            label-for="Name"
          >
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="settings.urway_password"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('payment_methods.urway_merchent_key')"
            label-for="body"
          >
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="settings.urway_merchent_key"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('payment_methods.urway_live_url')"
            label-for="body"
          >
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="settings.urway_live_url"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('payment_methods.urway_test_url')"
            label-for="body"
          >
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="settings.urway_test_url"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group :label="$t('payment_methods.description')">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-textarea
                :disabled="!catEdit"
                v-model="form_data.description"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        v-if="
          globalPermission['payment-methods'] &&
          globalPermission['payment-methods'].store
        "
        type="submit"
        variant="primary"
        block
        :disabled="isBusy"
        @click="paymentMethodsForm"
        class="primary-btn my-3"
      >
        <span>{{ $t("payment_methods.save") }}</span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BAvatar,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "URWay",
  components: {
    BAvatar,
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    Loading,
    BFormTextarea,
    BFormSelect,
    RecommendationUploadImage,
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
      current_id: null,
      form_data: {
        name: null,
        description: null,
        is_active: true,
        lang: null,
        icon: [],
      },
      settings: {
        urway_merchent_key: null,
        urway_password: null,
        urway_terminal_id: null,
        urway_live_url: null,
        urway_test_url: null,
        urway_life_url: null,
        urway_mode: null,
      },
      iconsUrl: [],
    };
  },

  created() {
    this.isLoading = false;

    this.current_id = 2;
    this.getPaymentMethod();
    this.geSettingsKeys();
  },
  computed: {
    catEdit() {
      return (
        this.globalPermission["payment-methods"] &&
        this.globalPermission["payment-methods"].update
      );
    },
  },
  methods: {
    async geSettingsKeys() {
      try {
        const settingsRequest = await this.$http.get("admin/settings");
        if ([200, 201].includes(settingsRequest.status)) {
          this.getMySettingsKeys(settingsRequest.data.data);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    getMySettingsKeys(data) {
      const wantedKeys = Object.keys(this.settings);
      for (const settingItem of data) {
        if (wantedKeys.includes(settingItem.key)) {
          if (settingItem.value_type === "numeric") {
            this.settings[settingItem.key] = +settingItem.value.value;
          } else {
            this.settings[settingItem.key] = settingItem.value.value;
          }
        }
      }
    },
    getPaymentMethod() {
      this.$http
        .get(`admin/payment-methods/${this.current_id}`)
        .then((res) => {
          if (res.status === 200) {
            for (const key in this.form_data) {
              if (key === "is_active") {
                this.form_data[key] = +res.data.data[key];
              } else if (key === "icon") {
                this.form_data.icon.length = 0;
                this.iconsUrl.length = 0;
                for (const img of res.data.data[key]) {
                  this.iconsUrl.push({ id: img.id, path: img.path });
                }
              } else {
                this.form_data[key] = res.data.data[key];
              }
            }
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async paymentMethodsForm() {
      const formData = new FormData();
      for (const key in this.form_data) {
        if ("icon" === key) {
          for (const iconIndex in this.form_data[key]) {
            formData.append(
              `icon[${iconIndex}][file]`,
              this.form_data[key][iconIndex]["fileObj"]
            );
          }
        } else {
          formData.append(key, this.form_data[key]);
        }
      }
      await this.submitSettingKeys();
      this.isBusy = true;
      this.$http
        .post(`admin/payment-methods/${this.current_id}?_method=put`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
            this.getPaymentMethod();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          this.isPending = false;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    async submitSettingKeys() {
      try {
        this.isBusy = true;
        await Promise.all([
          this.sendRequest("urway_terminal_id"),
          this.sendRequest("urway_password"),
          this.sendRequest("urway_merchent_key"),
          this.sendRequest("urway_test_url"),
          this.sendRequest("urway_live_url"),
          this.sendRequest("urway_mode"),
        ]);
      } catch (error) {
      } finally {
        this.isBusy = false;
      }
    },
    async sendRequest(key) {
      try {
        const formData = new FormData();
        formData.append("value", this.settings[key]);
        await this.$http.post(`admin/settings/${key}?_method=put`, formData);
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },

    handelIconsChange(event) {
      const files = event.target.files;
      files.forEach((ele) => {
        const url = URL.createObjectURL(ele);
        this.iconsUrl.push({ path: url });
        this.form_data.icon.push({
          fileObj: ele,
          path: url,
        });
      });
    },
    deleteMe(imgObj) {
      if (imgObj.hasOwnProperty("id")) {
        this.deleteImageWithId(imgObj);
      } else {
        this.deleteImageSoftly(imgObj.path);
      }
    },
    async deleteImageWithId(imageObj) {
      try {
        this.isBusy = true;

        await this.$http.delete(`admin/image/${imageObj.id}`);
        this.deleteImageSoftly(imageObj.path);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    deleteImageSoftly(path) {
      this.form_data.icon = this.form_data.icon.filter(
        (img) => img.path !== path
      );
      this.iconsUrl = this.iconsUrl.filter((img) => img.path !== path);
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
