<template>
  <Loading v-if="isLoading" />
  <div v-else class="payment-method">
    <div class="p-2 mb-1">
      <h1 class="m-0 page-title">
        {{ $t("payment_methods.payment_methods_infromation") }}
      </h1>
    </div>
    <div>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab :title="$t('payment_methods.urway')" active
            ><b-card-text>
              <URWay /> </b-card-text
          ></b-tab>
          <b-tab :title="$t('payment_methods.cash_on_delivery')"
            ><b-card-text> <CachOnDelivery /> </b-card-text
          ></b-tab>
          <b-tab :title="$t('payment_methods.bank_transfer')"
            ><b-card-text> <BankTransfer /> </b-card-text
          ></b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BTab, BTabs, BCardText } from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import URWay from "@/components/paymentMethod/URWay/index.vue";
import CachOnDelivery from "@/components/paymentMethod/CashOnDelivery/index.vue";
import BankTransfer from "@/components/paymentMethod/BankTransfer/index.vue";

export default {
  name: "PaymentMethod",
  components: {
    Loading,
    BCard,
    BTab,
    BTabs,
    BCardText,
    CachOnDelivery,
    BankTransfer,
    URWay,
  },
  data() {
    return {
      isLoading: true,
    };
  },

  created() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
