<template>
  <Loading v-if="isLoading" />
  <div v-else class="payment-method">
    <div class="p-2">
      <h1 class="m-0 page-title">
        {{ $t("payment_methods.bank_transfer") }}
      </h1>
    </div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <button
            :disabled="!catEdit"
            style="all: unset"
            @click="$refs.PaymentMethodIcoInput.$el.click()"
          >
            <b-avatar rounded size="3rem" class="enable-class" :src="icoUrl" />
          </button>
          <b-form-file
            ref="PaymentMethodIcoInput"
            plain
            hidden="hidden"
            @change="handelIconChange"
            v-model="form_data.icon"
          />
          <RecommendationUploadImage
            :dimensions="{ width: 512, height: 512 }"
          />
        </b-col>
        <b-col md="12">
          <b-form-checkbox
            :disabled="!catEdit"
            v-model="form_data.is_active"
            :value="1"
            :unchecked-value="0"
            class="enable-class"
          >
            {{ $t("payment_methods.enable") }}
          </b-form-checkbox>
        </b-col>

        <b-col md="12">
          <b-form-group :label="$t('payment_methods.label')" label-for="Name">
            <validation-provider
              #default="{ errors }"
              name="body"
              rules="required"
            >
              <b-form-input
                :disabled="!catEdit"
                type="text"
                v-model="form_data.name"
                placeholder=""
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group :label="$t('payment_methods.description')">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-textarea
                :disabled="!catEdit"
                v-model="form_data.description"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        v-if="catEdit"
        type="submit"
        variant="primary"
        :disabled="isBusy"
        block
        @click="paymentMethodsForm"
        class="primary-btn my-3"
      >
        <span>{{ $t("payment_methods.save") }}</span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
  BAvatar,
  BFormFile,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "BankTransfer",
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    Loading,
    BFormTextarea,
    BFormSelect,
    BAvatar,
    BFormFile,
    RecommendationUploadImage
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
      current_id: null,
      form_data: {
        name: null,
        description: null,
        is_active: true,
        icon: null,
      },
      icoUrl: null,
    };
  },
  computed: {
    catEdit() {
      return (
        this.globalPermission["payment-methods"] &&
        this.globalPermission["payment-methods"].update
      );
    },
  },
  created() {
    this.isLoading = false;
    this.current_id = 3;
    this.getPaymentMethod();
  },
  methods: {
    getPaymentMethod() {
      this.$http
        .get(`admin/payment-methods/${this.current_id}`)
        .then((res) => {
          if (res.status === 200) {
            for (const key in this.form_data) {
              if (key === "is_active") {
                this.form_data[key] = +res.data.data[key];
              } else if ("icon" === key) {
                const arrayOfIcons = res.data.data[key];
                this.icoUrl = arrayOfIcons[arrayOfIcons.length - 1]?.path;
              } else {
                this.form_data[key] = res.data.data[key];
              }
            }
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    paymentMethodsForm() {
      const formData = new FormData();
      for (const key in this.form_data) {
        if ("icon" === key && this.form_data["icon"]) {
          formData.append("icon[0][file]", this.form_data["icon"]);
        } else if ("icon" !== key) {
          formData.append(key, this.form_data[key]);
        }
      }
      formData.append("lang", localStorage.getItem("lang"));
      this.isBusy = true;
      this.$http
        .post(`admin/payment-methods/${this.current_id}?_method=put`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
            this.getPaymentMethod();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          this.isPending = false;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    handelIconChange(event) {
      const file = event.target.files[0];
      this.icoUrl = URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
